<template>
  <div id="base">
    <div id="img_container">
      <div id="img_content_1">
        <img alt="Vue logo" src="./assets/logo.png">
      </div>
      <div id="img_content_2">
        <div id="heading_container">
          <h1>
            Solutions for
          </h1>
          <h1>
            Urban environments
          </h1>
        </div>
      </div>
    </div>
    <div id="text_container">
      <div id="text_box">
        <p>
          Milzo Lithuania UAB
        </p>
        <p>
          Žygio g. 95-23,
        </p>
        <p>
          Vilnius, Lithuania, LT-08241
        </p>
        <p>
          +370 61455756
        </p>
        <p>
          <a href="mailto:sigitas.mocevicius@milzo.com">sigitas.mocevicius@milzo.com</a>
        </p>
      </div>
      <div id="text_box">
        <p>
          Milzo Latvia SIA
        </p>
        <p>
          Krišjāņa Valdemāra iela 73 - 7,
        </p>
        <p>
          Rīga, Latvia, LV-1013
        </p>
        <p>
          +371 22462325
        </p>
        <p>
          <a href="mailto:janis.pekmanis@milzo.com">janis.pekmanis@milzo.com</a>
        </p>
      </div>
<!--      <div id="text_box">-->
<!--        <p>-->
<!--          MilzoPoles-->
<!--        </p>-->
<!--        <p>-->
<!--          Bukultu iela 11,-->
<!--        </p>-->
<!--        <p>-->
<!--          Rīga, Latvia, LV-1005-->
<!--        </p>-->
<!--        <p>-->
<!--          +371 28049494-->
<!--        </p>-->
<!--        <p>-->
<!--          <a href="mailto:janis.pekmanis@milzo.com">reinis.leveika@milzopoles.com</a>-->
<!--        </p>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
* {
  margin:0;
  padding:0;
  box-sizing: border-box;
}

#base {
  width: 100%;
  height: 100vh;
  background-color: white;
}

#img_container {
  height: 80vh;
  background:linear-gradient(0deg, rgba(90, 90, 90, 0.3), rgba(90, 90, 90, 0.3)), url("/src/assets/background_img.jpg");
  //background-image: url("/src/assets/background_img.jpg");
  //background-color: rgba(128, 128, 128, 0.9);
  //background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;

  #img_content_1{
    width: 20%;
    img {
      margin-top: 5vh;
      width: 100%;
      min-width: 320px;
      max-width: 500px;
    }
  }
  #img_content_2{
    width: 80%;
    #heading_container {
      margin-top: 40vh;
      h1 {
        font-weight: 400;
        font-size: 86px;
        line-height: 100px;
        letter-spacing: 0.01em;
        color: #FFFFFF;
        text-align: left;
        width: 70%;
        min-width: 800px;
        margin: auto;
      }
    }
  }
}
#text_container {
  display: flex;
  align-items: center;
  height: 20vh;
    #text_box {
      display: inline-block;
      text-align: left;
      margin-left: max(50px, 5vw);
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 19px;
        line-height: 28px;
        color: #000000;
        a {
          color: #000000;
          text-decoration: none;
        }
      }
    }
}
@media (max-width: 1020px) or (max-height: 900px)/* or (max-height: 1000px)*/ {
  #img_container {
    height: 80vh;
    display: flex;

    #img_content_1{
      width: 20%;
      img {
        margin-top: 5vh;
        width: 100%;
        min-width: 300px;
        max-width: 400px;
      }
    }
    #img_content_2{
      width: 80%;
      #heading_container {
        margin-top: 40vh;
        h1 {
          font-size: 66px;
          line-height: 90px;

          width: 70%;
          min-width: 610px;
          margin: auto;
        }
      }
    }
  }

  #text_container {
    display: flex;
    align-items: center;
    height: 20vh;
    min-height: 185px;

    padding: calc((20vh - 140px) / 2) 0 0 max(50px, 5vw);

    flex-wrap: wrap;
    #text_box {
      //display: inline-block;
      //height: 20vh;

      text-align: left;
      margin: 0 max(50px, 5vw) calc((20vh - 140px) / 2) 0;
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 19px;
        line-height: 28px;
        color: #000000;
        a {
          color: #000000;
          text-decoration: none;
        }
      }
    }

    //#text_box:nth-child(2) {
    //  padding-bottom: 25px;
    //}
  }
}

@media (max-width: 667px)/* or (max-height: 1000px)*/ {
  #text_container {
    #text_box:nth-child(2) {
      padding-bottom: 25px;
    }
  }
}

@media (max-width: 800px) or (max-height: 900px) {
  #img_container {
    height: 70vh;
    display: flex;

    #img_content_1{
      width: 10%;
      img {
        margin-top: 5vh;
        min-width: 260px;
      }
    }
    #img_content_2{
      width: 90%;
      #heading_container {
        margin-top: 35vh;
        h1 {
          font-size: 56px;
          line-height: 80px;

          width: 70%;
          min-width: 530px;
          margin: auto;
        }
      }
    }
  }

  #text_container {
    display: flex;
    align-items: center;
    height: 30vh;
    min-height: 185px;

    padding: calc((30vh - 140px) / 2) 0 0 max(50px, 5vw);

    flex-wrap: wrap;
    #text_box {
      //display: inline-block;
      //height: 20vh;

      text-align: left;
      margin: 0 max(50px, 5vw) calc((30vh - 140px) / 2) 0;

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 19px;
        line-height: 28px;
        color: #000000;
        a {
          color: #000000;
          text-decoration: none;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  #img_container {
    height: 100vh;
    display: flex;

    #img_content_1{
      width: 10%;
      img {
        margin-top: 5vh;
        min-width: 240px;
      }
    }
    #img_content_2{
      width: 90%;
      #heading_container {
        margin-top: 50vh;
        h1 {
          font-size: 46px;
          line-height: 70px;

          width: 70%;
          min-width: 430px;
          margin: auto;
        }
      }
    }
  }

  #text_container {
    padding: 0 0;
    margin: 50px 0;
    #text_box {
      padding-left: calc((100vw - 243px) / 2);
      width: 100vw;
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #000000;
        a {
          color: #000000;
          text-decoration: none;
        }
      }
    }
    #text_box:nth-child(2) {
      padding-bottom: 50px;
    }
  }
}
@media (max-width: 500px) {
  #img_container {
    height: 100vh;
    display: flex;

    #img_content_1{
      width: 10%;
      img {
        margin-top: 5vh;
        min-width: 200px;
      }
    }
    #img_content_2{
      width: 90%;
      #heading_container {
        margin-top: 50vh;
        h1 {
          font-size: 36px;
          line-height: 50px;

          width: 100%;
          min-width: 0;
          margin: auto;
        }
      }
    }
  }
}
@media (max-height: 600px) {
  #text_container {
    #text_box {
      padding-bottom: 40px;
    }
  }
}
</style>
